import Modal from './modal.js';
import { getParameterByName } from '../../base/utils.js';
import { ResizeHandler, isMobileWidth } from '../../base/dom-utils.js';
import Analytics from '../../base/wyn-analytics-module.js';

class ExtendedHeroVideoSlide {
  constructor() {
    this.jwPlayers = {};
    this.onOpenVideoModal();
  }
  initVideos(videos) {
    if (videos) {
      if (!isMobileWidth()) {
        $.each(videos, (i, el) => {
          this.createVideos(i, el);
        });
        $(videos[0]).closest('.extended-hero-container')
          .addClass('videos-loaded');
      }
      // On Resize- check if videos have been loaded if not mobile
      ResizeHandler.addResizeEndFn(() => {
        if (!isMobileWidth() && !($('.extended-hero-container').hasClass('videos-loaded'))) {
          $.each(videos, (i, el) => {
            this.createVideos(i, el);
          });
        }
      });
    }
  }
  createVideos(i, el) {
    if ($(el).hasClass('slick-cloned')) {
      $(el).find('.background-video')
        .attr('id', 'clone' + i);
    }
    this.setupVideo(i, $(el).find('.background-video'), $(el));
  }
  setupVideo(i, el, container) {
    let id = $(el).attr('id');

    if (id) {
      this.jwPlayers[id] = jwplayer(id).setup({
        file: $(el).data('video-file'),
        playlist: $(el).data('video-playlist'),
        autostart: $(el).data('video-autostart'),
        controls: $(el).data('video-controls'),
        mute: $(el).data('video-mute'),
        repeat: $(el).data('video-loop'),
        stretching: $(el).data('video-stretching') || 'fill',
        height: $(el).data('video-height') || '100%',
        width: $(el).data('video-width') || '100%',
        displaydescription: false,
        displaytitle: false,
        preload: 'auto',
        autoPause: {
          viewability: true,
          pauseAds: true
        }
      })
        .on('ready', () => {
        // loop attr causes complete event don't fire
          $(this.jwPlayers[id].getContainer()).find('video')
            .removeAttr('loop');

          if ((jwplayer(id).getConfig().autostart) && ($(container).hasClass('slick-active'))) {
          // Direct call, autoplay isn't detected
            Analytics.satelliteTracker('jwplay');
          }
        })
        .on('complete', () => {
          // Analytics flags
          setTimeout(() => {
            $(this.jwPlayers[id].getContainer()).find('video')
              .attr('data-is-complete', true);
          }, 1);
        })
        .on('firstFrame', () => {
        // Analytics flag
          $(this.jwPlayers[id].getContainer()).find('video')
            .attr('data-is-started', true);
        })
        .on('play', () => {
          if (id.indexOf('clone') === -1) {
            this.analyticsMetaData(id);
          }
        });
    }
  }

  analyticsMetaData(id) {
    const playlistItem = jwplayer(id).getPlaylistItem().sources[0];
    let name = playlistItem.file,
      type = playlistItem.type;
    name = name.split('\\').pop()
      .split('/')
      .pop()
      .split('-')
      .shift();

    // Analytics data
    window.digitalData.rewards = {
      activity: {
        videoName: name,
        videoType: type
      }
    };
  }
  onOpenVideoModal() {
    $(document).on('click', 'a[href*="#openVideoModal"]', (e) => {
      e.preventDefault();
      let url = getParameterByName('url', $(e.currentTarget).attr('href'));
      if (url) {
        let $video = `<div id="videoModal" data-video-file="${url}"></div>`;
        Modal.populateModalContent($video);
        Modal.show();
        this.setupVideo(1, $('#videoModal'));
      }
    });
  }
}

export default new ExtendedHeroVideoSlide();
